<div class="container">
   <div class="title_bar" >
   <a class="back"  href="/workbenchdashboard" aria-label="back">4</a>
   <h2>Choose Publications <sub *ngIf="dataSource.data.length>0">({{updateDisplayedCount()}})</sub></h2>

   <div class="filter_bar">
      <div class="search_div">
         <input #filterText type="text" 
                class="search" 
                placeholder="Search by publication title, source type, subject" 
                onfocus="this.placeholder = ''" 
                onblur="this.placeholder = 'Search by publication title, source type, subject'" 
                (search)="applyFilter('')" 
                (keyup.enter)="applyFilter(filterText.value)" 
                (keyup)="filterText.value"/>

         <span class ='clear' (click)="applyFilter(''); filterText.value=''">{{filterText.value!=='' ? 'n' : ''}}</span>
      </div>
      <div class="filter" (click)="applyFilter(filterText.value)" >;</div>
   </div>
</div>
<div *ngIf="isLoading" class="spinner" fxLayoutAlign="center">
   <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
</div>
<div *ngIf="isLoading" class="spinner_label"><p>Loading Publications...</p></div>
<p *ngIf="!isResults" class="warning">* No results found for "{{search}}"</p>
<div class="table" *ngIf="!isLoading">
   <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 data-table">
         <!--select column-->
         <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> Select </mat-header-cell>
            <mat-cell *matCellDef="let row">
                    <mat-checkbox class="select_checkbox1"
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelected(row); updateCount()"
                        [checked]="isSelected(row)">
                    </mat-checkbox>
            </mat-cell>
         </ng-container>

         <!--publication title column-->
         <ng-container matColumnDef="publicationTitle">
            <mat-header-cell *matHeaderCellDef > <div class="header">Publication Title </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let publication" class="pc-pub-title" >
               <!-- <p tabindex="0" (click)="goToPubPage($event, publication.id)" (keyup.enter)="goToPubPage($event, publication.id)">{{getPublicationTitle(publication)}}</p> -->
               <p class= "pc-pub-title" tabindex="0" (keyup.enter)="goToPubPage($event, publication.id)">
                  <a (click)="goToPubPage($event, publication.id)" target="_blank" rel="noopener">
                     {{getPublicationTitle(publication)}}
                     <span class="screen-reader-only">(opens in a new tab)</span>
                     </a>
               </p>
            </mat-cell>
         </ng-container>

         <!--Date Range column-->
         <ng-container matColumnDef="dateRange">
            <mat-header-cell *matHeaderCellDef> Date Range </mat-header-cell>
            <mat-cell *matCellDef="let publication">{{getDateRange(publication)}}</mat-cell>
         </ng-container>

         <!--Publication ID-->
         <ng-container matColumnDef='id'>
            <mat-header-cell *matHeaderCellDef >Publication ID <span class="help" (mouseover)="isPubIdTip = true" (mouseleave)="isPubIdTip = false" >V</span>
               <div class="tool-tip id-tip" [ngStyle]="(isPubIdTip) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                  <p class="tool-label">Publication ID</p>
                  <span class="tool-text">The Publication ID is a unique number to identify the publication. This can be valuable when working with multiple publications which have the same name for example different versions of The New York Times.</span>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let publication">{{ publication.id}}</mat-cell>
         </ng-container>         

         <!--sourceTpe column-->
         <ng-container matColumnDef="sourceType">
            <mat-header-cell *matHeaderCellDef>
               <div class="header"> Source Type </div>
               <!-- <div *ngIf="isSourceTypeFilter" 
                  class="sourceType dataset-bubble" 
                  tabindex="-1" clickOutside 
                  (clickOutside)="isSourceTypeFilter = false;">
                  <input #filterText 
                           type="text" 
                           class="mini_search" 
                           placeholder="Filter by Source Type" 
                           value="{{saveSourceTypeFilterText}}" 
                           (search)="applyFilter('sourceType','')" 
                           (keyup)="applyFilter('sourceType',filterText.value)"/>

                  <ul class="list" >
                     <li class="list_entry" *ngFor="let sourceType of sourceTypes">
                        <mat-checkbox class="list_span" *ngIf="sourceType.length<=28" 
                           [checked]="selectionSourceType.isSelected(sourceType)" 
                           (click)="$event.stopPropagation()"
                           (change)='selectionSourceType.toggle(sourceType);'>{{sourceType}}</mat-checkbox>
                        <mat-checkbox class="list_span" *ngIf="sourceType.length>28" 
                           [checked]="selectionSourceType.isSelected(sourceType)" 
                           (click)="$event.stopPropagation()"
                           (change)='selectionSourceType.toggle(sourceType);'>{{sourceType | slice:0:25}} ...</mat-checkbox>
                     </li>
                  </ul>
               </div> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let publication">{{getSourceType(publication)}}</mat-cell>
         </ng-container>

         <!--subject-->
         <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef >
               <div class="header"> Subject </div>
               <!-- <div *ngIf="isSubjectFilter" 
                  class="subject dataset-bubble" 
                  tabindex="-1" 
                  clickOutside 
                  (clickOutside)="isSubjectFilter = false;">

                  <input #filterText 
                        type="text" 
                        class="mini_search" 
                        placeholder="Filter by Subject" 
                        value="{{saveSubjectFilterText}}" 
                        (search)="applyFilter('subject','')" 
                        (keyup)="applyFilter('subject',filterText.value)"/>

                  <ul class="list" >
                     <li class="list_entry" *ngFor="let subject of subjects">
                        <mat-checkbox class="list_span" *ngIf="subject.length<=28" 
                           [checked]="selectionSubject.isSelected(subject)" 
                           (click)="$event.stopPropagation()"
                           (change)='selectionSubject.toggle(subject);'>{{subject}}</mat-checkbox>
                        <mat-checkbox class="list_span" *ngIf="subject.length>28" 
                           [checked]="selectionSubject.isSelected(subject)" 
                           (click)="$event.stopPropagation()"
                           (change)='selectionSubject.toggle(subject);'>{{subject | slice:0:25}} ...</mat-checkbox>
                     </li>
                  </ul>
               </div> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let publication">{{getSubject(publication)}}</mat-cell>
         </ng-container>

         <!--Full Text-->
         <ng-container matColumnDef="fullText">
            <mat-header-cell *matHeaderCellDef ><div class="header" > Full Text </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let publication"><p *ngIf="isFullText(publication) === 'true'" class="fullText" title="Full Text">i</p></mat-cell>
         </ng-container>

         <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
         <mat-row *matRowDef="let row; columns: displayedColumns;"  (click)="toggleSelected(row); updateCount()" >
         </mat-row>
   </mat-table>
</div>
<mat-paginator [ngClass] = "isLoading ? 'hidden' : ''" 
               [pageSize] = "pageSize" 
               [hidePageSize] = "length < 20"
               [pageSizeOptions] = "[20, 50, 100]"
               [length] = "length" 
               [pageIndex] = "pageIndex"
               (page)="onPagination($event)" 
               showFirstLastButtons>
</mat-paginator>

  <tdms-dataset-selection-footer *ngIf="noOfPubs >0"
        [noOfItemsSelected]=noOfPubs
        selectionLabel= "Publication"
        select= " selected"
        btnLabel ="Next: Refine Content"
        nextStep="RefineContent"
        (pubCartEvent)="openPubCart($event)">
   </tdms-dataset-selection-footer>
</div>