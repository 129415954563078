import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class DatasetService {
    constructor() {
        this.isRefineConent = new BehaviorSubject(false);
        this.currentRefineConentStatus = this.isRefineConent.asObservable();
        this.isCreateDataset = new BehaviorSubject(false);
        this.currentCreateDatasetStatus = this.isCreateDataset.asObservable();
        this.datasetTypeSource = new Subject();
        this.currentDatasetType = this.datasetTypeSource.asObservable();
    }
    selectDatasetType(datasetType) {
        localStorage.setItem('datasetType', datasetType);
    }
    selectNextPage() {
        if (!this.isRefineConent.value) {
            this.isRefineConent.next(true);
        }
        else if (!this.isCreateDataset.value) {
            this.isCreateDataset.next(true);
        }
    }
    selectPreviousPage() {
        if (this.isCreateDataset.value) {
            this.isCreateDataset.next(false);
        }
        else if (this.isRefineConent.value) {
            this.isRefineConent.next(false);
        }
    }
    currentPubDatasetPage() {
        if (this.isRefineConent.value) {
            return 'RefineContent';
        }
        else if (this.isCreateDataset.value) {
            return 'CreatePubdataset';
        }
        else {
            return 'findContent';
        }
    }
    getDataSetType() {
        return localStorage.getItem('datasetType') === null ? 'Publication' : localStorage.getItem('datasetType');
    }
    isDatabase() {
        let datasetType = this.getDataSetType();
        return (datasetType === 'Database');
    }
    isPublication() {
        let datasetType = this.getDataSetType();
        return (datasetType === 'Publication');
    }
    isCongressional() {
        let datasetType = this.getDataSetType();
        return (datasetType === 'Congressional');
    }
}
DatasetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetService_Factory() { return new DatasetService(); }, token: DatasetService, providedIn: "root" });
