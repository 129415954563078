import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject, of, from, Subject, combineLatest, concat } from 'rxjs';
import { toArray, tap, switchMap, catchError, takeUntil, map } from 'rxjs/operators';
import { Facet, Hit, DocSearchRequest, PubDocumentSearchResponse, PubFacetResponseType } from 'src/app/_shared/models/publication-results.model';
import { PublicationService } from 'src/app/dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { TopSearch } from 'src/app/shared/models/dataset.model';
import * as _m from 'moment';
import { DocLimitModalComponent } from 'src/app/shared/modal/doc-limit-modal/doc-limit-modal.component';


@Component({
  selector: 'tdms-analysis-search',
  templateUrl: './analysis-search.component.html',
  styleUrls: ['./analysis-search.component.scss']
})
export class AnalysisSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  
  searchField: FormControl;
  error: string ='';
  clearFilter: boolean = false;
  isPublication = false;
  isShowFilters = false;
  topSearches:TopSearch[] = [];
  topSearchLeft:TopSearch[] = [];
  topSearchRight:TopSearch[] = [];
  showTooltip:string;
  showTooltipHover:string;

  pubFacetSubscription:Subscription[] =[];
  pubFacetResults$;

  @HostListener('keydown.escape')
  hideTooltip(): void {
    this.showTooltip = '';
    this.showTooltipHover = '';
  }

  @ViewChild('filterOverlay', {static:false}) set content(content: ElementRef) {
    if(content) { 
        let height = content.nativeElement.offsetHeight;
        content.nativeElement.style.marginBottom = "-" + height + "px";
    }
  };

  @ViewChild('searchBar', {static: false}) searchBar: ElementRef;

  //searchTermSubject and respective action stream...
  private searchTermSubject = new BehaviorSubject<string>("");
  searchTermAction$ =  this.searchTermSubject.asObservable();


  //currently selected workbech observable.
  currentlySelectedWb$ =  of(localStorage.getItem('workbench'));

  //prepare observable for selected PubIds
  selectedPubIds$ =  from(JSON.parse(localStorage.getItem('selectedPubs')) === null ? []:  JSON.parse(localStorage.getItem('selectedPubs')))
                      .pipe(
                        map( val => ({"searchValue": val})),
                        toArray(),
                        map( val => JSON.stringify(val)),
                        tap( val => console.log("selectedPubIds => ", val)
                      )
  );


  displayFilters$ = this.pubservice.displayFilter$;

  pubOrderAsc$ =  of('pubDateAsc');

  pubOrderDesc$ = of('pubDateDesc');

  cancelPubFacetRequest$ = new Subject<void>();
                
  
  searchRequestAsc$ = combineLatest([this.searchTermAction$, this.currentlySelectedWb$, this.pubOrderAsc$])
        .pipe(
          map(([searchTerm, wbId,sortOrderAsc]) => {
            //Trigger pubFacet request as soon as searchRequest is available.
            // const pubFacetReq = this.pubservice.generateSearchRequest(searchTerm, wbId, sortOrderAsc, 'PubFacet');
            // this.fetchPubFacets(pubFacetReq);
            return this.pubservice.generateAnalysisSearchRequest(searchTerm, wbId, sortOrderAsc)
          }),
          tap( val => console.log( 'SQ =>', val))
        ); 

  //combine all the observables before search request.
  searchRequestDesc$ = combineLatest([this.searchTermAction$, this.currentlySelectedWb$, this.pubOrderDesc$, ])
                    .pipe(
                      map(([searchTerm, wbId, sortOrderDesc]) => {
                        return this.pubservice.generateAnalysisSearchRequest(searchTerm, wbId, sortOrderDesc)
                      }),
                      tap( val => console.log( 'SQ =>', val))
                    );


  
  //Build resultsSteam based on searchRequest stream.
  docresultsDesc$ = this.searchRequestDesc$.pipe(
    tap(searchReq =>  console.log('searchReq =>', JSON.stringify(searchReq))),
    switchMap(searchReq => 
      concat(
        of( { type: 'start'}),
        this.pubservice.performAnalysisSearch(searchReq)
                        .pipe( 
                            map(searchResp => ({ type: 'finish', searchResp})),
                            catchError(err => of( { type: 'finish', err}))
                        )
      ))
  );

  docresultsAsc$ = this.searchRequestAsc$.pipe(
    tap(searchReq =>  console.log('searchReq =>', JSON.stringify(searchReq))),
    switchMap(searchReq => 
      concat(
        of( { type: 'start'}),
        this.pubservice.performAnalysisSearch(searchReq)
                        .pipe( 
                            map(searchResp => ({ type: 'finish', searchResp})),
                            catchError(err => of( { type: 'finish', err}))
                        )
      ))
  );

  //Following observable to collect the date range from Asc and Dec requests.
  //This is final observable subscribed from tml using async operator.
  dateRange$ =  combineLatest([this.docresultsDesc$, this.docresultsAsc$])
                  .pipe(
                    tap(([descResultsObj, ascResultsObj]) => {

                    })
                  );
                  
  constructor(private pubservice: PublicationService, 
              private dataSetService: DatasetService, 
              private titleService: Title, 
              private dialog:MatDialog, 
              private router:Router) { }



  ngOnInit() {
      this.titleService.setTitle("Create new dataset: Search");
      let currentOrDefaultSearchTerm = this.pubservice.currentSearchTerm();
      this.searchField = new FormControl(currentOrDefaultSearchTerm);

      this.applyFilters(currentOrDefaultSearchTerm);
      this.isPublication =  this.dataSetService.isPublication();
      //this.getTopSearches();

      this.scrollToTop();
  }

  getTopSearches(){
    const wbId = localStorage.getItem('workbench') ? localStorage.getItem('workbench') : '';
    this.pubservice.getTopSearches(wbId).subscribe(resp => {
      this.topSearches = resp.popularDatabase ? resp.popularDatabase : [];

      /**
       * This is to address if workbench is not selected and popularSearch endpoint is returning empty obect.
       */
      if(!this.topSearches) return this.topSearches = [];


      //parse date range out of title
      for(let search of this.topSearches){
        if(search.name.includes('1')){
          let name = search.name;
          search.name = search.name.slice(0,search.name.indexOf('1'));
          search.time = name.slice(name.indexOf('1'));
        }
      }
      //combine historic and currnert into one entry
      for(let search of this.topSearches){  
        if (this.topSearches.filter(x => x.rank == search.rank).length > 1){
          let searches = this.topSearches.filter(x => x.rank == search.rank);
          let current = searches[0].time.localeCompare(searches[1].time) > 0 ? searches[0] : searches[1];
          let historic = searches[0].time.localeCompare(searches[1].time) > 0 ? searches[1] : searches[0];

          let combinedSearch:TopSearch = { 
            moniker: current.moniker,
            name: current.name,
            historicMoniker: historic.moniker,
            description: current.description,
            full_description: current.full_description,
            rank:current.rank,
            time:current.time,
            historicTime: historic.time
          }
          this.topSearches = this.topSearches.filter(x => x.rank !== search.rank);
          this.topSearches.push(combinedSearch);
        }
      }
      
      this.topSearches.sort((a,b) => parseInt(a.rank) - parseInt(b.rank));
      this.topSearchLeft = this.topSearches.slice(0, Math.ceil(this.topSearches.length/2));
      this.topSearchRight = this.topSearches.slice(Math.ceil(this.topSearches.length/2));
    }, (error) => {
      console.log("Tops Search Error ---> ",error);
      this.topSearches = [];
    });
  }

  showTopSearch(obsRes):boolean{
    if(!this.topSearches) return false;
    return this.topSearches.length > 0
      && obsRes[0].type === 'finish'  && obsRes[1].type === 'finish'
      && !((JSON.parse(localStorage.getItem("sReq")).search.filters && (JSON.parse(localStorage.getItem("sReq")).search.filters.length > 0  || JSON.parse(localStorage.getItem("sReq")).search.products.length > 0))) 
      && (JSON.parse(localStorage.getItem("sReq")).search.query == "\"artificial intelligence\" or \"text mining\" or \"machine learning\" or \"data science\"" || JSON.parse(localStorage.getItem("sReq")).search.query == "");
  }

  public search(searchValue: string) {
    this.searchField.setValue(searchValue);
    localStorage.setItem("searchValue" , searchValue);
    this.cancelPubFacetRequest$.next();
    this.searchTermSubject.next(searchValue);
  }

  clear(){
    this.searchField.reset();
    this.searchField.setValue('');
    localStorage.setItem("searchValue" , '');
    this.setSearchBarFocus();
  }

  isDocs(docsFound:number):boolean{
    return docsFound > 0;
  }

  pubFacets(facets: Facet[]) {
    if(facets){
      const pubfacets = this.pubservice.getPubFacets(facets);
      localStorage.setItem("pubFacets", JSON.stringify(pubfacets));
      return pubfacets;
    } else {
      localStorage.setItem("pubFacets", JSON.stringify(this.getFacetsfromSearchReq('pubTitle')));
    }
    return JSON.parse(localStorage.getItem("pubFacets"));
    
  }

  sourceFacets(facets: Facet[]) {
    if(facets){
      return this.pubservice.getSourceFacets(facets);
    } else {
      return this.getFacetsfromSearchReq('sourcetype');
    }
    
  }

  docFacets(facets: Facet[]) {
    if(facets){
      return this.pubservice.getDocTypeFacets(facets);
    } else {
      return this.getFacetsfromSearchReq('objectype');
    }
    
  }

  dbFacets(facets: Facet[]) {
    const dbFacets = facets ? this.pubservice.getDbFacets(facets) : this.getFacetsfromSearchReq('databases');
    localStorage.setItem("dbFacets", JSON.stringify(dbFacets));
    return dbFacets;
  }

  getFacetsfromSearchReq(facetType:string){
    const facets = [];
    if(JSON.parse(localStorage.getItem("sReq")).search.filters.find(filter => filter.name == facetType)){
      for (let facet of JSON.parse(localStorage.getItem("sReq")).search.filters.find(filter => filter.name == facetType).entries){
        facets.push({
          "searchValue": facet.searchValue,
          "displayValue": facet.searchValue,
          "count": 0
        });
      }
    }
    return facets;
  }

  applyTopSearch(moniker:string, name:string){
    localStorage.setItem("dbFilters",JSON.stringify([{moniker:moniker, name:name}]))
    this.clear();
    this.applyFilters("");
  }

  applyFilters(searchValue?: string) {   
    if(searchValue != null){
      this.searchTermSubject.next(searchValue);
    } else {
      this.searchTermSubject.next(localStorage.getItem("searchValue") ? localStorage.getItem("searchValue"): "");
    }

    
    this.pubservice.dbProduct$.subscribe(val => console.log(val) )
  
    this.isShowFilters = false;

   this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }


  public displayClearFilters(): boolean | false {
    return this.pubservice.displayClearFilters();

  }

  openSearchTipsPage() {
    window.open("https://www.proquest.com/help/academic/webframe.html?Search_Tips.html#Search_Tips.html", "_blank");
  }

  getFooterHeight(numOfDocs:number):number{
    
    if(numOfDocs < 100000){
      return 92;
    }

    const extraDigits = numOfDocs.toString().length - 5;
    const commas = Math.ceil(numOfDocs.toString().length / 3) - 1;

    return 72 + (extraDigits*9) + (commas*5);
  }

  onToggleShowFilters(){
    this.isShowFilters = !this.isShowFilters;
  }
  formatDate(date:string):string{
    return _m(new Date(date)).utcOffset(0,false).format('MMM DD, YYYY');
  }

  isAppliedFilters(){
    return  localStorage.getItem('docFilters') || localStorage.getItem('sourceFilters') || localStorage.getItem('fromDate') ||  localStorage.getItem('toDate');
  }

  displayDateRange(results: Hit[], resultsAsc: Hit[]) {
    let dateDesc = results[0].field.find(element => element.name === 'numStartDate').value;
    let dateAsc = resultsAsc[0].field.find(element => element.name === 'numStartDate').value;
    return this.formatDate(dateAsc[0]) + ' to ' +this.formatDate(dateDesc[0]);
  }

  createDataset(docCount:number){
    const maxDocCount = 10000;
    const dialogData = { docCount: maxDocCount};

    if(docCount > maxDocCount){
      const dialogRef: MatDialogRef<DocLimitModalComponent> = this.dialog.open(DocLimitModalComponent, { 
        disableClose: true, 
        maxWidth:'520px',
        data: dialogData

      });
    } else {
      localStorage.setItem("docCount", docCount.toString());
      this.router.navigateByUrl("/analysis/createdataset")
    }
  }

  showInfo(isHover:boolean, index:string){
    if(isHover){
      this.showTooltipHover = this.showTooltipHover==index ? '' : index;
    } else {
      this.showTooltip = this.showTooltip==index ? '' : index;
    }
    
    
  }

  private fetchPubFacets(pubFacetSearchReq: DocSearchRequest) {
  console.log('Fetching pubfacets....', JSON.stringify(pubFacetSearchReq));

  this.pubFacetResults$ = of(pubFacetSearchReq).pipe(
      takeUntil(this.cancelPubFacetRequest$),
      switchMap(searchReq => 
        concat(
          of( { type: 'start'}),
          this.pubservice.search(searchReq)
                          .pipe( 
                              map((searchResp: PubDocumentSearchResponse) => {
                                let pubFacetResp: PubFacetResponseType = { type: 'finish', searchResp};
                                console.log('pubFacets call completed...', pubFacetResp.searchResp.facets.length);
                                return pubFacetResp;
                              }),
                              catchError(err => of( { type: 'finish', err}))
                          )
        )))

  }

  ngAfterViewInit(): void {
    console.log('wb-refinement  ngAfterViewInit method....');
    this.setSearchBarFocus();
  }

  setSearchBarFocus() {
    this.searchBar.nativeElement.focus();
  }

  /**
   * This method is used to inform filter component to render lazyResponse.
   * This is temporary commented out as we are not using this approach.
   
    onFilterComponentRendered() {
      console.log('wb-refinemnt onFilterComponentRendered event...');
      this.pubFacetResults$.subscribe((res: PubFacetResponseType) => {

          if(res.type === 'finish') {
              console.log('Informing filter component to render lazyResponse @@@@ ', res.type);
              this.pubservice.setLazyPubFacetSubjectV1(res);
          }
          
        });      
      
    }
  */

  ngOnDestroy(): void {
    console.log('ngOnDestroy method...');
    localStorage.removeItem("dbList_Vis");
  }



}
