import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatasetService {


  private isRefineConent = new BehaviorSubject(false);
  currentRefineConentStatus = this.isRefineConent.asObservable();

  private isCreateDataset = new BehaviorSubject(false);
  currentCreateDatasetStatus = this.isCreateDataset.asObservable();

  private datasetTypeSource = new Subject<string>();
  currentDatasetType = this.datasetTypeSource.asObservable();


  constructor() { }

  selectDatasetType(datasetType: string) {
    localStorage.setItem('datasetType', datasetType);
  }

  selectNextPage() {
    if (!this.isRefineConent.value) {
      this.isRefineConent.next(true);
    } else if (!this.isCreateDataset.value) {
      this.isCreateDataset.next(true);
    }
  }

  selectPreviousPage() {
    if (this.isCreateDataset.value) {
      this.isCreateDataset.next(false);
    } else if (this.isRefineConent.value) {
      this.isRefineConent.next(false);
    }
  }

  currentPubDatasetPage() {
    if (this.isRefineConent.value) {
      return 'RefineContent';
    } else if (this.isCreateDataset.value) {
      return 'CreatePubdataset';
    } else {
      return 'findContent';
    }
  }

  getDataSetType() {
    return localStorage.getItem('datasetType') === null ? 'Publication' : localStorage.getItem('datasetType');
  }

  isDatabase() {
    let datasetType =  this.getDataSetType();
    return  (datasetType === 'Database');
  }

  isPublication() {
    let datasetType =  this.getDataSetType();
    return  (datasetType === 'Publication');
  }

  isCongressional() {
    let datasetType =  this.getDataSetType();
    return  (datasetType === 'Congressional');
  }

}
