import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import * as _m from 'moment';
import { Subscription, from } from 'rxjs';
import { Database } from 'src/app/_shared/models/publication-search.model';
import { PublicationService } from 'src/app/dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { map, mergeMap, shareReplay, switchMap, tap, toArray } from 'rxjs/operators';
import { AuthtokenService } from 'src/app/auth/authtoken.service';
import { VizPublicationService } from 'src/app/analysis/analysis-dataset/analysis-refine-content/analysis-refinement.service';
import { AnalysisService } from 'src/app/analysis/analysis.service';

@Component({
    selector: 'tdms-pub-select-modal',
    templateUrl: './pub-select-modal.html',
    styleUrls: ['./filterpanel.component.scss']
})
export class PubSelectModalComponent implements OnInit {

    @ViewChild("modal_first", { static: false }) firstTabIndex: any;
    @ViewChild("modal_last", { static: false }) lastTabIndex: any;
    @ViewChild("modal_search_box", { static: false }) searchBox: any;
    @HostListener('window:keydown.space', ['$event']) spaceEvent(event: any) {
        if (event.target.className.includes("ps-row")) { event.preventDefault(); }
    }

    pubTableRequest: Subscription;
    dbTableRequest: Subscription;
    search: string = "";
    pageSize: number = 100;
    pageIndex: number = 0;
    workbench: string = localStorage.getItem("workbench") ? localStorage.getItem("workbench") : '';
    numberOfPubsAvailable: number = 100;
    length: number = 0;
    maxPubCount: number = 10;
    tableList = [];
    isLoading: boolean = true;
    selectedItems = [];
    isPubData: boolean;
    searchPlaceholder: string = this.data.filterType == 'pub' ? 'Search by publication title, source type, subject' : 'Databases';
    isAsc = true;
    isSearchPopulated = false;
    dataSource: MatTableDataSource<Database> = new MatTableDataSource(this.tableList);
    text: string;



    constructor(public dialogRef: MatDialogRef<PubSelectModalComponent>,
        private pubservice: PublicationService,
        private visPubservice: VizPublicationService,
        private visDbservice: AnalysisService,
        @Inject(MAT_DIALOG_DATA) public data,
        private authTokenService: AuthtokenService) { }

    ngOnInit() {
        this.isPubData = this.data.filterType === 'pub';
        if(this.data.isWb){
            if (this.isPubData) { 
                this.getPubData(); 
                if (this.data.filters){ this.selectedItems = Object.assign([],this.data.filters) }
            } else { this.getDbData(); }
        } else {
            if (this.isPubData) { 
                this.getVisPubData(); 
                if (this.data.filters){ this.selectedItems = Object.assign([],this.data.filters) }
            } else { this.getVisDbData(); }
        }
        
    }

    ngAfterViewInit() {
        setTimeout(() => { this.searchBox.nativeElement.focus() }, 500);
    }

    ngOnDestroy() {
        if (this.pubTableRequest) { this.pubTableRequest.unsubscribe(); }
        if (this.dbTableRequest) { this.dbTableRequest.unsubscribe(); }
    }

    getPubData() {
        this.pubTableRequest = this.pubservice.getPublications(this.search, this.workbench, this.pageSize, this.pageIndex * this.pageSize)
            .subscribe(resolverResponse => {
                this.tableList = resolverResponse.hits;
                this.length = resolverResponse.docsFound;
                this.isLoading = false;
                this.searchBox.nativeElement.focus();
            });
    }

    getVisPubData() {
        this.pubTableRequest = this.visPubservice.getPublications(this.search, this.pageSize, this.pageIndex * this.pageSize)
            .subscribe(resolverResponse => {
                this.tableList = resolverResponse.hits;
                this.length = resolverResponse.docsFound;
                this.isLoading = false;
                this.searchBox.nativeElement.focus();
            });
    }


    loopTabindex(index: string, event: KeyboardEvent) {
        if (index === 'lastTabIndex') {
            event.preventDefault();
            this.firstTabIndex.nativeElement.focus();
        } else if (event.shiftKey && event.key == 'Tab') {
            event.preventDefault();
            this.lastTabIndex.nativeElement.focus();
        }
    }

    getDbData() {
        this.pubservice.getDatabaseSubscription2(this.workbench).subscribe(response => console.log("RESPONSE => ", response));
        let subscriptionInfo$ = this.pubservice.getDatabaseSubscription(this.workbench)
            .pipe(
                switchMap(dbResponse =>
                    from(dbResponse.products)
                        .pipe(
                            mergeMap(db => this.pubservice.getMonikerInfo(db.moniker).pipe(shareReplay(1))),
                            toArray(),
                            map(dbList => dbList.sort((a, b) => a.name > b.name ? 1 : -1)),
                            tap(dbList => dbList),
                        )
                ));
        this.tableList = localStorage.getItem("dbList_" + this.workbench) ? JSON.parse(localStorage.getItem("dbList_" + this.workbench)) : [];


        if (this.tableList.length < 1) {
            this.dbTableRequest = subscriptionInfo$.subscribe(dbList => {
                dbList = dbList.filter(db => db.description || db.name !== "TDM - WOS");
                localStorage.setItem("dbList_" + this.workbench, JSON.stringify(dbList));

                this.tableList = dbList;
                this.dataSource.data = this.tableList;
                if (this.data.filters) {
                    this.getSelectedDbs();
                }
                this.isLoading = false;
            });
        } else {
            this.dataSource.data = this.tableList;
            if (this.data.filters) {
                this.getSelectedDbs();
            }
            this.isLoading = false;
        }
    }

    getVisDbData() {
        this.visDbservice.getDatabaseSubscription2().subscribe(response => console.log("RESPONSE => ", response));
        let subscriptionInfo$ = this.visDbservice.getDatabaseSubscription()
            .pipe(
                switchMap(dbResponse =>
                    from(dbResponse.products)
                        .pipe(
                            mergeMap(db => this.pubservice.getMonikerInfo(db.moniker).pipe(shareReplay(1))),
                            toArray(),
                            map(dbList => dbList.sort((a, b) => a.name > b.name ? 1 : -1)),
                            tap(dbList => dbList),
                        )
                ));
        this.tableList = localStorage.getItem("dbList_Vis") ? JSON.parse(localStorage.getItem("dbList_Vis")) : [];


        if (this.tableList.length < 1) {
            this.dbTableRequest = subscriptionInfo$.subscribe(dbList => {
                dbList = dbList.filter(db => db.description || db.name !== "TDM - WOS");
                localStorage.setItem("dbList_Vis", JSON.stringify(dbList));

                this.tableList = dbList;
                this.dataSource.data = this.tableList;
                if (this.data.filters) {
                    this.getSelectedDbs();
                }
                this.isLoading = false;
            });
        } else {
            this.dataSource.data = this.tableList;
            if (this.data.filters) {
                this.getSelectedDbs();
            }
            this.isLoading = false;
        }
    }

    getDate(pub): string {
        let startDate = pub.find(x => x.name === "startDate") ? pub.find(x => x.name === "startDate").value : '';
        let endDate = pub.find(x => x.name === "endDate") ? pub.find(x => x.name === "endDate").value : '';

        if (startDate && endDate) {
            return _m(startDate).format('YYYY') + " - " + _m(endDate).format('YYYY');
        } else if (startDate) {
            return _m(startDate).format('YYYY');
        } else if (endDate) {
            return _m(endDate).format('YYYY');
        }
        return '';
    }

    getFullText(pub): string {
        return pub.find(x => x.name === "hasFullText") ? 'Full text' : '';
    }

    getTitle(pub): string {
        return pub.find(x => x.name === "title") ? pub.find(x => x.name === "title").value : '';
    }

    getSourceType(pub): string {
        return pub.find(x => x.name === "sourceType") ? pub.find(x => x.name === "sourceType").value : '';
    }

    getSubject(pub): string {
        return pub.find(x => x.name === "subject") ? pub.find(x => x.name === "subject").value.toString().replace("|"," | ") : '';
    }

    getDbTitle(db: Database) {
        return db.name;
    }

    getDbDescription(db: Database): string {
        if (db.description.split(" ").length > 20) {
            let desc = db.description.split(" ");
            desc.length = 20;
            return desc.join(" ") + '...';
        }
        return db.description;;
    }

    getDbFullText(db: Database): string {
        return db.hasFullText ? 'Full text' : '';
    }

    onSearch(value: string) {
        if (this.isPubData) {
            this.isLoading = true;
            this.search = value;
            if (this.data.isWb){ this.getPubData(); } else { this.getVisPubData(); }
            this.getPubData();
        } else {
            this.dataSource.filter = value;
            this.tableList = this.dataSource.filteredData;
        }
    }

    onLiveSearch(value: string) {
        if (!this.isPubData) { this.onSearch(value); }
    }

    getSelectedDbs() {

        for (let db of this.data.filters) {
            if (this.tableList.find(database => database.moniker == db.moniker)) {
                this.selectedItems.push(this.tableList.find(database => database.moniker == db.moniker));
            }
        }
    }

    clearFilter(filter){
        this.selectedItems = this.isPubData ? this.selectedItems.filter(x => x.id !== filter.id) : this.selectedItems.filter(x => x.moniker !== filter.moniker);
        this.data.filters = this.selectedItems;
    }

    getModalTitle() {
        if (this.isPubData) {
            return "Publication Titles (" + this.length + ")"
        }
        return "Databases (" + this.tableList.length + ")"
    }

    isChecked(item): boolean {
        if (this.isPubData) {
            return this.selectedItems.filter(x => x.id === item.id).length > 0;
        }
        return this.selectedItems.filter(x => x.moniker === item.moniker).length > 0;
    }

    togglePubSelection(pub) {
        //Extract the pubId and pubTitle from pub-row.
        let title = '';
        let id = pub.id;
        pub.field.forEach(item => {
            if (item.name === 'title') {
                title = item.value[0];
            }
        });
        
        if (this.isChecked(pub)) {
            this.selectedItems = this.selectedItems.filter(x => x.id !== pub.id);
        } else {
            this.selectedItems.push({id, title});
            console.log("SELECTED ITEMS => ", JSON.stringify(this.selectedItems));
        }
    }

    clearSelection() {
        this.selectedItems = [];
    }

    toggleDbSelection(db) {
        if (this.isChecked(db)) {
            this.selectedItems = this.selectedItems.filter(x => x.moniker !== db.moniker);
        } else {
            this.selectedItems.push(db);
        }
    }

    onDone() {
        if (this.isPubData) {
            let selectedPubFilterValues = [];
            console.log("SELECTED ITEMS => ", this.selectedItems);
            //this.selectedItems.map(item => item.field.filter(x => x.name === 'title').map(i => selectedPubFilterValues.push(i.value[0])));
            this.dialogRef.close({ event: 'pubSelect', data: this.selectedItems })
        } else {
            this.dialogRef.close({ event: 'dbSelect', data: this.selectedItems })
        }
    }

    goToPubPage(event: Event, pubId: number) {
        event.stopPropagation();
        this.authTokenService.openPubPage(pubId);
    }

    goToDbPage(event: Event, moniker: string) {
        event.stopPropagation();
        this.authTokenService.openDatabasePage(moniker);
    }

}
