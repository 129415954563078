<!-- <h2 ngClass="doccount"  ngClass.md="doccount-md" tabindex="0">{{ docCount | number }} documents</h2> -->

<div class="container" tabindex="0">
    <div class="resultItem" *ngFor="let item of results; let i = index" tabindex="0">
      <div class="title-row-wrapper">
        <div ngClass="title"> <span class="title-link" (click)="openDocView(item)"> {{ getTitle(item) }} </span></div>
        <div class="title-row-pubdate"> {{ getPubDate(item) }}</div>
      </div>  
      <div [ngClass]="(results.length-1 === i && results.length > 39) ? 'citation' : 'citation border'" >{{ getPubTitle(item) }}</div>
    </div>
  </div>
  
