<Dataset-Header stage="{{getTrueDatasetType() == 'Congressional' ? 'congressional-create' : 'create'}}"></Dataset-Header>

<div *ngIf = 'isLoading' class="center">
    <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
</div>
<div *ngIf = '!isLoading'>
    <div class="create_header">
        <h2 class="summary">Summary</h2>
        <div class="details">
            <div class="bold summary_label">Document Count: </div> <div  class="summary_label">{{docCount | number}}</div> <div></div>
        </div>
        <div class="details">
            <div  class="bold summary_label">{{getDatasetType()}}s: </div> <div class="summary_label">{{getContentList()}}</div> <div></div>
        </div>
        <div *ngIf="isQuery">
            <div (click)='toggleQuery()'>
                <p *ngIf='!isShowQuery' class="show_query">Show Query</p> <p *ngIf='isShowQuery' class="show_query">Hide Query</p>
                <p *ngIf='!isShowQuery' class="caret">K</p> <p *ngIf='isShowQuery' class="caret">=</p>
            </div>
        </div>
        <div *ngIf='isShowQuery' class="query">{{query}}</div>
        
    </div>


    <div class="create_body">
        <h2 class="dataset_details">Dataset Details</h2>

        <form [formGroup]="createDataset" (ngSubmit)="onCreate(createDataset.value)">
            <div><p class="name label" id="name">Name</p><p class="required label">*</p></div>
            <textarea [ngClass]= "isError ? 'error textbox' : 'textbox'" form="createDataset" formControlName='name' aria-labelledby="name" aria-required="true"></textarea>
            <p *ngIf="isError" class= "error_message">{{errorMessage}}</p>
            <p class="margin label" id="description">Description</p>
            <textarea class = 'textbox' form="createDataset" formControlName='description' aria-labelledby="description"></textarea>
            <div *ngIf="!isCreating" (click)="onCreate(createDataset.value)" class="create" tabindex="0" role="button">Create Dataset</div>
            <div *ngIf="isCreating" class="create disabled"><div class="spinner"><mat-spinner [diameter]="30" [strokeWidth]="3"></mat-spinner></div></div>
        </form>
    </div>
</div>
