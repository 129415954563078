import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';
import { Field, Hit, PubDocumentSearchResponse } from 'src/app/_shared/models/publication-results.model';
import { IPubResultItem } from 'src/app/_shared/models/pubresult-item.model';
import { AuthtokenService } from 'src/app/auth/authtoken.service';
import { PublicationService } from 'src/app/dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service';
import * as _m from 'moment';



@Component({
  selector: 'tdms-wb-pub-results',
  templateUrl: './wb-pub-results.component.html',
  styleUrls: ['./wb-pub-results.component.scss']
})
export class WbPubResultsComponent implements OnInit {

  pubResults: IPubResultItem[];
  pubresultsSubscription: Subscription;
  pubDocumentResponse: PubDocumentSearchResponse;   
  dateRange = '';

  dateRange$ =  this.pubService.dateRangeSubject$;
  dateRangeSubscription: Subscription;

  @Input() results: Hit[];
  @Input() resultsAsc: Hit[];
  @Input() docCount: number;
  @Input() dateRange1: string;

  constructor(private pubService: PublicationService,  
               private tokenService: AuthtokenService, 
               private locationService: Location) { }


  public ngOnInit(): void {}

  parseText(input: string): string {
    const pattern1 = /\[\[mkup\]\]/g;

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(input, 'text/html');

    return input.replace(pattern1, '');
  }

  getFiledValue(field: Field[], fieldName: string): string {
    let rtnValue = '';
    field.map( fieldObj => {
      if(fieldObj.name === fieldName)
        rtnValue =  this.parseText(fieldObj.value[0]);
    })
    return rtnValue;
  }
  getTitle(hit: Hit): string {

    const title = hit.field.find(element => element.name === 'title')

    return this.parseText(title.value[0]);

  }

  getPubTitle(hit: Hit): string {
    return hit.field.find(element => element.name === 'pubTitle').value.toString();
  }

  getPubDate(hit: Hit): string {
    return hit.field.find(element => element.name === 'pubDate').value.toString();
  }



  getCitation(hit: Hit): string {

    const author =  hit.field.find(element => element.name === 'author');
    const pubtitle = hit.field.find(element => element.name === 'pubTitle');
    const pubdate = hit.field.find(element => element.name === 'pubDate');

    let citation = [];

    if(author.value.length>1) {
      citation.push(author.value);
    }

    if(author.value.length>1) {
      citation.push(", ");
    }

    citation.push(this.parseText(pubtitle.value.toString()));

    if(pubtitle.value.toString().length>1) citation.push(", ");

    if(pubdate.value !==null) {
      citation.push(pubdate.value);
    }
    return citation.join("");
    //return [author.value, '. ', this.parseText(pubtitle.value.toString()), ', ', pubdate.value].join("");
  }

  /**
   * Link to docview page on PQP by using token/
   */
  openDocView(resultsItem: Hit) {

    return this.tokenService.openDocview(resultsItem.id);

  }

  displayDateRange() {
    return this.docCount > 0 && !this.locationService.path().includes('/analysis');
  }

  getDateRange() {
    if(this.docCount >0 && !this.locationService.path().includes('/analysis')) {

      let dateDesc = this.results[0].field.find(element => element.name === 'numStartDate').value;
      let dateAsc = this.resultsAsc[0].field.find(element => element.name === 'numStartDate').value;
      return this.formatDate(dateAsc[0]) + ' to ' +this.formatDate(dateDesc[0]);


   }
  }

  formatDate(date:string):string{
    //console.log()
    //moment().utcOffset(0, true).format()
    return _m(new Date(date)).utcOffset(0,false).format('MMM DD, YYYY');
  }


}

