/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wb-pub-results.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./wb-pub-results.component";
import * as i6 from "../../dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service";
import * as i7 from "../../auth/authtoken.service";
var styles_WbPubResultsComponent = [i0.styles];
var RenderType_WbPubResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WbPubResultsComponent, data: {} });
export { RenderType_WbPubResultsComponent as RenderType_WbPubResultsComponent };
function View_WbPubResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "resultItem"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "title-row-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["ngClass", "title"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(5, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "title-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDocView(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "title-row-pubdate"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(13, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(14, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "title"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "title"; _ck(_v, 5, 0, currVal_1); var currVal_4 = ((((_co.results.length - 1) === _v.context.index) && (_co.results.length > 39)) ? "citation" : "citation border"); _ck(_v, 12, 0, currVal_4); var currVal_5 = ((((_co.results.length - 1) === _v.context.index) && (_co.results.length > 39)) ? "citation" : "citation border"); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getTitle(_v.context.$implicit); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.getPubDate(_v.context.$implicit); _ck(_v, 9, 0, currVal_3); var currVal_6 = _co.getPubTitle(_v.context.$implicit); _ck(_v, 14, 0, currVal_6); }); }
export function View_WbPubResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WbPubResultsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.results; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WbPubResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-wb-pub-results", [], null, null, null, View_WbPubResultsComponent_0, RenderType_WbPubResultsComponent)), i1.ɵdid(1, 114688, null, 0, i5.WbPubResultsComponent, [i6.PublicationService, i7.AuthtokenService, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WbPubResultsComponentNgFactory = i1.ɵccf("tdms-wb-pub-results", i5.WbPubResultsComponent, View_WbPubResultsComponent_Host_0, { results: "results", resultsAsc: "resultsAsc", docCount: "docCount", dateRange1: "dateRange1" }, {}, []);
export { WbPubResultsComponentNgFactory as WbPubResultsComponentNgFactory };
